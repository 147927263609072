import axios from "../core/axios";
import { assertSuccess, paramsParser } from "../core";
import { Headers } from "./generic";
import type {
  CollectionResult,
  CompactResult,
  ConnectionCreatePostBody,
  ConnectionSearchParams,
  DiscordAccountEntity,
  DiscordGuildConnectionEntity,
  DiscordGuildInfoEntity,
  DiscordGuildTierRoleConnectionEntity,
  DiscordTierCreatePostBody,
  DiscordTierUpdatePostBody,
  GenericResult,
  InviteDiscordBotParams,
  MessageResult,
  Result,
} from "../types";
import { AxiosError } from "axios";

export class Discord {
  static async getAccount(auth: string) {
    const resp = await axios
      .get<Result<DiscordAccountEntity>>(`/discord/account`, {
        headers: Headers.Bearer(auth),
      })
      .catch((e) => {
        if (e instanceof AxiosError && e.response?.status === 404) return null;
        else throw e;
      });

    return resp && assertSuccess(resp.data).data;
  }

  static async connectAccount(secret: string, auth: string) {
    const resp = await axios.get<CompactResult<{ redirectUri: string }>>(
      `/discord/connect${paramsParser({ secret })}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  static async finalizeCallback(code: string, auth: string) {
    const resp = await axios.post<Result<DiscordAccountEntity>>(
      `/discord/callback`,
      { code },
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(resp.data).data;
  }

  static async disconnectAccount(auth: string) {
    const resp = await axios.post<
      GenericResult<"DiscordAccount connection removed">
    >(`/discord/disconnect`, undefined, {
      headers: Headers.Bearer(auth),
    });

    return assertSuccess(resp.data);
  }

  static async inviteDiscordBot(params: InviteDiscordBotParams, auth: string) {
    const resp = await axios.get<CompactResult<{ redirectUri: string }>>(
      `/discord/bot/invite${paramsParser(params)}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  static async getGuildInfo(guildId: string, auth: string) {
    const resp = await axios
      .get<Result<DiscordGuildInfoEntity>>(`/discord/guild/${guildId}`, {
        headers: Headers.Bearer(auth),
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.status === 404)
          return null;
        throw error;
      });

    return resp ? assertSuccess(resp.data).data : null;
  }

  static async getGuildConnection(orgId: string, auth: string) {
    const resp = await axios
      .get<Result<DiscordGuildConnectionEntity>>(
        `/discord/organization/${orgId}`,
        {
          headers: Headers.Bearer(auth),
        },
      )
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.status === 404)
          return null;
        throw error;
      });

    return resp ? assertSuccess(resp.data).data : null;
  }

  static async createGuildConnection(
    orgId: string,
    data: ConnectionCreatePostBody,
    auth: string,
  ) {
    const resp = await axios.post<Result<DiscordGuildConnectionEntity>>(
      `/discord/organization/${orgId}/create`,
      data,
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(resp.data).data;
  }

  static async getTierRoleConnection(
    orgId: string,
    params: ConnectionSearchParams,
    auth: string,
  ) {
    const resp = await axios.get<
      CollectionResult<DiscordGuildTierRoleConnectionEntity>
    >(`/discord/organization/${orgId}/tier${paramsParser(params)}`, {
      headers: Headers.Bearer(auth),
    });

    return resp ? assertSuccess(resp.data).data : null;
  }

  static async createTierRoleConnection(
    orgId: string,
    data: DiscordTierCreatePostBody,
    auth: string,
  ) {
    const resp = await axios.post<Result<DiscordGuildTierRoleConnectionEntity>>(
      `/discord/organization/${orgId}/tier/create`,
      data,
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(resp.data).data;
  }

  static async updateTierRoleConnection(
    orgId: string,
    tierConnectionId: string,
    data: DiscordTierUpdatePostBody,
    auth: string,
  ) {
    const resp = await axios.post<Result<DiscordGuildTierRoleConnectionEntity>>(
      `/discord/organization/${orgId}/tier/${tierConnectionId}`,
      data,
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(resp.data).data;
  }

  static async deleteTierRoleConnection(
    orgId: string,
    tierConnectionId: string,
    auth: string,
  ) {
    const resp = await axios.delete<
      MessageResult<"Discord Tier Role Connection removed">
    >(`/discord/organization/${orgId}/tier/${tierConnectionId}`, {
      headers: Headers.Bearer(auth),
    });

    return assertSuccess(resp.data);
  }

  static async deleteGuildConnection(
    orgId: string,
    connectionId: string,
    version: number,
    auth: string,
  ) {
    const resp = await axios.delete<
      MessageResult<"Discord Guild Connection removed">
    >(`/discord/organization/${orgId}/${connectionId}?version=${version}`, {
      headers: Headers.Bearer(auth),
    });

    return assertSuccess(resp.data);
  }
}
